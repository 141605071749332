import * as React from "react"

import styled from "styled-components"

import { AppStateContext } from "../../../utils/PageWrapper"
import { Col, Row } from "react-flexbox-grid"
import { _Blockquote } from "../../shared/blockquote.styles"
import { _h2 } from "../../shared/headline.styles"
import HplisticImg from "../../../images/holistic/program.svg"

interface HolisticSectionProps {
    title: string | JSX.Element
    lead: JSX.Element
}

const _holisticImg = styled.img`
    transform: translate(0, -10%);
`

export const HolisticSection: React.FC<HolisticSectionProps> = ({ title, lead }) => {
    const appState = React.useContext(AppStateContext)

    return (
        <>
            <Row center="xs">
                {!appState.isMobile && (
                    <Col lg={6} md={10} sm={12}>
                        <_holisticImg src={HplisticImg} />
                    </Col>
                )}

                <Col lg={5} lgOffset={1} md={10} sm={12}>
                    <_h2 fontSizeSm={36} maxWidth="442px" align="left">
                        {title}
                    </_h2>

                    {appState.isMobile && <img style={{ margin: "auto", width: "100%" }} src={HplisticImg} />}

                    <_Blockquote>{lead}</_Blockquote>
                </Col>
            </Row>
        </>
    )
}
