import * as React from "react"
import { Layout, _Section, HeadComponent } from "../../components/shared"
import { graphql } from "gatsby"
import { Hero } from "../../components/productsPage/shared"
import RangerImage from "../../images/ranger/ranger-funnel.svg"
import { FrameworkSection, HolisticSection } from "../../components/productsPage/Ranger"
import { prismicGetText, prismicGetRichText, extractPrismicNode, prismicGetRichHeader } from "../../utils/prismicHelper"
import { PageWrapper } from "../../utils/PageWrapper"

export const prismicQuery = graphql`
    {
        prismic {
            ...rangerFragment
        }
    }
`
interface RangerPageProps {
    data: {
        prismic: {
            allProduct_rangers: {
                edges: any
            }
        }
    }
}

const RangerPage: React.FC<RangerPageProps> = ({ data }) => {
    // Required check for no data being returned
    const doc = extractPrismicNode(data, "allProduct_rangers")
    if (!doc) return null

    return (
        <>
            <HeadComponent
                title="Ranger | Radicle"
                keywords={prismicGetText(doc.seo_keywords_ranger)}
                description={prismicGetText(doc.seo_description_ranger)}
            />

            <PageWrapper>
                <Layout>
                    <_Section>
                        <Hero
                            description="Ranger"
                            title={prismicGetRichHeader(doc.ranger_title_v2)}
                            lead={prismicGetText(doc.ranger_lead)}
                            buttonLabel={prismicGetText(doc.cta_label_ranger)}
                            image={RangerImage}
                            leftSideLgWidth={6}
                            rightSideOffset={0}
                            heroImage={{
                                height: "auto",
                                isBlured: false,
                                topOffset: "-40px"
                            }}
                            showImageOnMobile={false}
                        />
                    </_Section>

                    <_Section>
                        <HolisticSection
                            title={prismicGetRichHeader(doc.holistic_title_v2)}
                            lead={prismicGetRichText(doc.holistic_lead)}
                        />
                    </_Section>

                    <_Section>
                        <FrameworkSection
                            title={prismicGetRichHeader(doc.framework_title_v2)}
                            lead={prismicGetText(doc.framework_lead)}
                            frameworks={doc.body[0].fields}
                        />
                    </_Section>
                </Layout>
            </PageWrapper>
        </>
    )
}

export default RangerPage
