import * as React from "react"
import { Col, Row } from "react-flexbox-grid"
import { _Blockquote } from "../../shared/blockquote.styles"
import { _h2 } from "../../shared/headline.styles"
import { GenericItem } from "../../shared"
import { prismicGetText } from "../../../utils/prismicHelper"
import { _customMarginRow } from "../../shared/row.styles"

interface FrameworkSectionProps {
    title: string | JSX.Element
    lead: string
    frameworks: [
        {
            framework_description: any
            framework_image: any
        }
    ]
}

export const FrameworkSection: React.FC<FrameworkSectionProps> = ({ title, lead, frameworks }) => {
    return (
        <>
            <Row center="xs">
                <Col lg={6} md={10} sm={12}>
                    <_h2 marginBottom={"0"}>{title}</_h2>
                    <_Blockquote marginTop={1} align="center">
                        {lead}
                    </_Blockquote>
                </Col>
            </Row>

            <_customMarginRow center="xs">
                {frameworks.map((framework, index: number) => {
                    return (
                        <Col lg={3} md={6} sm={12} key={index}>
                            <GenericItem
                                lead={prismicGetText(framework.framework_description)}
                                image={framework.framework_image.url}
                                cardImageClassName="smallImage"
                            />
                        </Col>
                    )
                })}
            </_customMarginRow>
        </>
    )
}
